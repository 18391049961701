/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

Vue.use(VueRouter)

const permissions_pages = {
  all: 'home',
  admin_menu_home: 'home',

  admin_account_all: 'pages-purchase-order',
  purchase_order: 'pages-purchase-order',
  purchase_order_data_entry: 'pages-pickup',

  admin_scan_review: 'pages-scan',

  admin_payment: 'pages-payment',

  admin_menu_news: 'pages-news',

  admin_menu_message_api: 'pages-message-api',

  admin_product_worn_out: 'pages-product-worn_out',

  admin_stock_all: 'pages-phone-brand',
  admin_phone_brand: 'pages-phone-brand',
  admin_phone_model: 'pages-phone-model',
  admin_product_stock: 'pages-product-offline',
  admin_product_online: 'pages-product-online',
  admin_product_type: 'pages-product-type',
  admin_pattern: 'pages-pattern',
  pattern_set_prices: 'pages-pattern-set-price',
  price_rates: 'pages-price-rate',

  admin_reconcile_amount: 'pages-reconcile',
  admin_reconcile_report: 'pages-report-reconcile',

  admin_report_all: 'pages-report-tax-number',
  admin_report_overall: 'pages-report-overall',
  admin_report_tax_number: 'pages-report-tax-number',
  admin_report_booking_order: 'pages-report-booking-order',
  admin_report_package: 'pages-claim',
  admin_report_credit: 'pages-report-credit',
  admin_report_credit_money: 'pages-report-credit-money',
  admin_report_package_credit: 'pages-claim',
  admin_order_scan_billing: 'pages-order-scan-billing',

  admin_sell_all: 'pages-orders',
  admin_bank: 'pages-bank',
  admin_transport_type: 'pages-transport-type',
  admin_manage_sell_mem: 'pages-orders',
  admin_claim: 'pages-claim',
  transport_expense_rates: 'pages-transport-expense-rate',
  admin_config: 'pages-config',

  admin_package_all: 'pages-report-add-package',
  admin_package_report: 'pages-report-add-package',
  admin_package_add: 'pages-add-package',
  admin_package_manage: 'pages-package',

  admin_package_credit_all: 'pages-credit-package',
  admin_package_credit_report: 'pages-credit-package',
  admin_package_credit_add: 'pages-add-credit-package',
  admin_package_credit_manage: 'pages-manage-credit-package',

  stock_employee_all: 'pages-stock-employee',
  stock_employee_create: 'pages-stock-employee-create',
  stock_employee_report: 'pages-report-product-amount',

  admin_point_all: 'pages-point',
  admin_point_manage: 'pages-point',

  // admin_point_manage_product : 'pages-product-point',
  admin_point_manage_product: 'pages-member-product-point',

  admin_credit_customers: 'pages-credit-customer',

  admin_data_shop_all: 'pages-main-data',
  admin_main_data: 'pages-main-data',
  admin_payments: 'pages-payment-setting',

  admin_employee_all: 'pages-employee',
  admin_employee_type_manage: 'pages-employee-type',
  admin_employee: 'pages-employee',

  admin_member_all: 'pages-customers',
  admin_member_type_manage: 'pages-customers-type',
  admin_member_manage: 'pages-customers',

  admin_role_all: 'pages-role',
  admin_role: 'pages-role',

  user_home: 'user_home',
  user_profile: 'pages-profile',
  user_manage_sell_mem: 'user-stock-product',
  user_report: '',
  user_stock_product: 'user-stock-product',
  user_payment: 'pages-payment-setting',
  user_claim: 'pages-claim',

}

const routes = [
  {
    path: '/',
    redirect: '/admin/home',
  },
  {
    path: '/admin/home',
    name: 'home',
    component: () => import('@/views/home/index.vue'),

    // async beforeEnter(to, from, next) {
    //   try {
    //     if (to.name !='login'){
    //       await store.dispatch("permissionCheck",to);
    //       if (this.$store.state.hasPermission) {
    //         next()
    //       }else{
    //         next('login')
    //       }
    //     }else{
    //       next();
    //     }
    //   } catch (e) {
    //
    //     next('login')
    //   }
    // }
  },
  {
    path: '/home',
    name: 'user_home',
    component: () => import('@/views/pages/user/home/index'),
  },
  {
    path: '/package',
    name: 'user-package',
    component: () => import('@/views/pages/user/package/index'),
  },
  {
    path: '/tracking/:id',
    name: 'pages-tracking',
    component: () => import('../views/pages/tracking.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/follow-pay',
    name: 'user-follow-pay',
    component: () => import('@/views/pages/user/package/followPay'),
  },
  {
    path: '/follow-package-credit',
    name: 'user-follow-package-credit',
    component: () => import('@/views/pages/user/package/followPackageCredit'),
  },
  {
    path: '/check-stock-product',
    name: 'user-stock-product',
    component: () => import('@/views/pages/user/stock-product/index'),
  },
  {
    path: '/report-tax',
    name: 'user-report-tax',
    component: () => import('@/views/pages/user/report/reportTax'),
  },
  {
    path: '/report-booking-order',
    name: 'user-report-booking-order',
    component: () => import('@/views/pages/user/report/reportBookingOrder'),
  },
  {
    path: '/report-package',
    name: 'user-report-package',
    component: () => import('@/views/pages/user/report/reportPackage'),
  },
  {
    path: '/report-credit',
    name: 'user-report-credit',
    component: () => import('@/views/pages/user/report/reportCredit'),
  },
  {
    path: '/report-credit-money',
    name: 'user-credit-money',
    component: () => import('@/views/pages/user/report/reportCreditMoney'),
  },
  {
    path: '/report-product-point',
    name: 'user-product-point',
    component: () => import('@/views/pages/user/report/reportProductPoint'),
  },

  {
    path: '/',
    redirect: 'home',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/typography',
    name: 'typography',
    component: () => import('@/views/typography/Typography.vue'),
  },
  {
    path: '/icons',
    name: 'icons',
    component: () => import('@/views/icons/Icons.vue'),
  },
  {
    path: '/cards',
    name: 'cards',
    component: () => import('@/views/cards/Card.vue'),
  },
  {
    path: '/simple-table',
    name: 'simple-table',
    component: () => import('@/views/simple-table/SimpleTable.vue'),
  },
  {
    path: '/form-layouts',
    name: 'form-layouts',
    component: () => import('@/views/form-layouts/FormLayouts.vue'),
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
  },
  {
    path: '/login',
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/forget-password',
    name: 'pages-forget-password',
    component: () => import('@/views/pages/ForgetPassword.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/register',
    name: 'pages-register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/admin/pickup',
    name: 'pages-pickup',
    component: () => import('@/views/pages/buy/Pick_up.vue'),
  },
  {
    path: '/admin/cost',
    name: 'pages-cost',
    component: () => import('@/views/pages/buy/cost.vue'),
  },
  {
    path: '/admin/purchase-order',
    name: 'pages-purchase-order',
    component: () => import('@/views/pages/buy/Purchase_order.vue'),
  },
  {
    path: '/pages/supplier',
    name: 'pages-supplier',
    component: () => import('@/views/pages/buy/Supplier.vue'),
  },
  {
    path: '/admin/news',
    name: 'pages-news',
    component: () => import('@/views/pages/news/index.vue'),
  },
  {
    path: '/admin/message-api',
    name: 'pages-message-api',
    component: () => import('@/views/pages/message-api/index.vue'),
  },
  {
    path: '/pages/pay',
    name: 'pages-pay',
    component: () => import('@/views/pages/sale/Pay.vue'),
  },
  {
    path: '/pages/tax',
    name: 'pages-tax',
    component: () => import('@/views/pages/sale/tax.vue'),
  },
  {
    path: '/pages/salary',
    name: 'pages-salary',
    component: () => import('@/views/pages/employee/employess/Salary.vue'),
  },
  {
    path: '/pages/financial-statement',
    name: 'pages-financial-statement',
    component: () => import('@/views/pages/report/FinancialStatements.vue'),
  },
  {
    path: '/pages/audit',
    name: 'pages-audit',
    component: () => import('@/views/pages/report/Audit.vue'),
  },
  {
    path: '/pages/sales',
    name: 'pages-sales',
    component: () => import('@/views/pages/report/Sales.vue'),
  },
  {
    path: '/admin/product',
    name: 'pages-product',
    component: () => import('@/views/pages/report/Product.vue'),
  },
  {
    path: '/admin/product/:id',
    name: 'pages-product-detail',
    component: () => import('@/views/pages/stock/productDetail.vue'),
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/order_print/:id',
    name: 'print',
    component: () => import('../views/pages/print/index'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/print_invoice/:id',
    name: 'print-invoice',
    component: () => import('../views/pages/print/print_invoice'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/export_product_worn_out',
    name: 'export_product_worn_out',
    component: () => import('../views/pages/print/export_worn_out.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/print_envelope/:id',
    name: 'print-envelope',
    component: () => import('../views/pages/print/print_envelope'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/print_stock_employee/:id',
    name: 'print-stock-employee',
    component: () => import('../views/pages/print/print_stock_employee'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/print_member_order/:id',
    name: 'print-member-order',
    component: () => import('../views/pages/print/print_member_order'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/product_worn_out_export',
    name: 'print',
    component: () => import('../views/pages/print'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
  {
    path: '/pages/manage-product',
    name: 'pages-manage-product',
    component: () => import('../views/pages/manage-product/Product.vue'),
  },
  {
    path: '/pages/transit',
    name: 'pages-transit',
    component: () => import('../views/pages/transit-page/TransitPageMain.vue'),
  },
  {
    path: '/pages/storefront',
    name: 'pages-storefront',
    component: () => import('../views/pages/store/StoreMain.vue'),
  },
  {
    path: '/pages/analytics',
    name: 'pages-analytics',
    component: () => '',
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/admin/orders',
    name: 'pages-orders',
    component: () => import('@/views/pages/bill/BillMain.vue'),
  },
  {
    path: '/admin/create/order/:id',
    name: 'pages-order-create',
    component: () => import('@/views/pages/bill/create.vue'),
  },
  {
    path: '/admin/orders/detail/:id',
    name: 'pages-order-detail',
    component: () => import('@/views/pages/bill/BillDetail.vue'),
  },
  {
    path: '/pages/draft',
    name: 'pages-draft',
    component: () => import('@/views/pages/bill/Draft.vue'),
  },
  {
    path: '/pages/not-paid',
    name: 'pages-notpaid',
    component: () => import('@/views/pages/bill/NotPaid.vue'),
  },
  {
    path: '/pages/paid',
    name: 'pages-paid',
    component: () => import('@/views/pages/bill/Paid.vue'),
  },
  {
    path: '/pages/make-send',
    name: 'pages-make-send',
    component: () => import('@/views/pages/bill/MakeSend.vue'),
  },
  {
    path: '/pages/send',
    name: 'pages-send',
    component: () => import('@/views/pages/bill/Send.vue'),
  },
  {
    path: '/pages/expire',
    name: 'pages-expire',
    component: () => import('@/views/pages/bill/Expire.vue'),
  },
  {
    path: '/pages/cancel',
    name: 'pages-cancel',
    component: () => import('@/views/pages/bill/Cancel.vue'),
  },
  {
    path: '/pages/open-bill',
    name: 'pages-open-bill',
    component: () => import('../views/pages/test_page.vue'),
  },
  {
    path: '/pages/bill',
    name: 'pages-bill',
    component: () => import('../views/pages/seemoreBill.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/review',
    name: 'pages-review',
    component: () => import('../views/pages/review.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/bill-tax',
    name: 'pages-bill-tax',
    component: () => import('../views/pages/bill-tax/Bill_Tax.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/bill-pay',
    name: 'pages-bill-pay',
    component: () => import('../views/pages/bill-tax/Bill_pay.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/widthHolder',
    name: 'pages-width-holder',
    component: () => import('../views/pages/bill-tax/Bill_withholding.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/tax',
    name: 'pages-tax',
    component: () => import('../views/pages/taxPage.vue'),
    meta: {
      layout: 'blank',
    },
  },

  // {
  //   path: '/home',
  //   name: 'home',
  //   component: () => import('../views/pages/MainPage.vue'),
  // },
  {
    path: '/pages/test',
    name: 'pages-test',
    component: () => import('../views/pages/AddProduct.vue'),
  },
  {
    path: '/pages/edit-product',
    name: 'pages-edit-product',
    component: () => import('../views/pages/manage-product/EditProductPage.vue'),
  },
  {
    path: '/pages/customer',
    name: 'pages-customer',
    component: () => import('../views/pages/conclude-data/Sell_Customer.vue'),
  },
  {
    path: '/pages/test-step',
    name: 'pages-test-step',
    component: () => import('../views/pages/StepTestPage.vue'),
  },
  {
    path: '/admin/scan',
    name: 'pages-scan',
    component: () => import('../views/pages/scan/index.vue'),
  },
  {
    path: '/admin/payment',
    name: 'pages-payment',
    component: () => import('../views/pages/payment/index.vue'),
  },
  {
    path: '/pages/sand-box-page',
    name: 'pages-sand-box-page',
    component: () => import('../views/pages/SandBoxPage.vue'),
  },
  {
    path: '/admin/product-worn_out',
    name: 'pages-product-worn_out',
    props: true,
    component: () => import('../views/pages/productWornOut/index'),
  },
  {
    path: '/admin/stock/phone-brand',
    name: 'pages-phone-brand',
    props: true,
    component: () => import('../views/pages/stock/phoneBrand'),
  },

  {
    path: '/admin/stock/phone-model',
    name: 'pages-phone-model',
    props: true,
    component: () => import('../views/pages/stock/phoneModel'),
  },
  {
    path: '/admin/stock/product-offline',
    name: 'pages-product-offline',
    props: true,
    component: () => import('../views/pages/stock/productOffline'),
  },
  {
    path: '/admin/stock/product-online',
    name: 'pages-product-online',
    props: true,
    component: () => import('../views/pages/stock/product'),
  },
  {
    path: '/admin/product_print_qr',
    name: 'print_qr',
    component: () => import('../views/pages/print/print_qr'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/admin/stock/product-type',
    name: 'pages-product-type',
    props: true,
    component: () => import('../views/pages/stock/productType'),
  },
  {
    path: '/admin/stock/pattern',
    name: 'pages-pattern',
    props: true,
    component: () => import('../views/pages/stock/pattern'),
  },
  {
    path: '/admin/stock/pattern-set-price',
    name: 'pages-pattern-set-price',
    props: true,
    component: () => import('../views/pages/stock/patternSetPrice'),
  },
  {
    path: '/admin/stock/pages-price-rate',
    name: 'pages-price-rate',
    props: true,
    component: () => import('../views/pages/stock/priceRate'),
  },

  {
    path: '/admin/claim',
    name: 'pages-claim',
    component: () => import('../views/pages/claim/list'),
  },
  {
    path: '/admin/bank',
    name: 'pages-bank',
    component: () => import('../views/pages/bank/index'),
  },
  {
    path: '/admin/transport-type',
    name: 'pages-transport-type',
    component: () => import('../views/pages/transport-type/index'),
  },
  {
    path: '/admin/transport-expense-rate',
    name: 'pages-transport-expense-rate',
    component: () => import('../views/pages/transport-expense-rate/index'),
  },
  {
    path: '/admin/config',
    name: 'pages-config',
    component: () => import('../views/pages/config/index'),
  },
  {
    path: '/admin/reconcile',
    name: 'pages-reconcile',
    props: true,
    component: () => import('../views/pages/reconcile/list'),
  },
  {
    path: '/admin/report-reconcile',
    name: 'pages-report-reconcile',
    props: true,
    component: () => import('../views/pages/reconcile/report'),
  },
  {
    path: '/admin/report/tax-number',
    name: 'pages-report-tax-number',
    props: true,
    component: () => import('../views/pages/report/reportTaxNumber'),
  },
  {
    path: '/admin/report/overall',
    name: 'pages-report-overall',
    props: true,
    component: () => import('../views/pages/report/overAll'),
  },
  {
    path: '/admin/report/report-sell',
    name: 'pages-report-sell',
    props: true,
    component: () => import('../views/pages/report/reportSale'),
  },
  {
    path: '/admin/report/report-buy',
    name: 'pages-report-buy',
    props: true,
    component: () => import('../views/pages/report/reportBuy'),
  },
  {
    path: '/admin/report/report-product',
    name: 'pages-report-product',
    props: true,
    component: () => import('../views/pages/report/reportProduct'),
  },
  {
    path: '/admin/report/report-customer',
    name: 'pages-report-customer',
    props: true,
    component: () => import('../views/pages/report/reportCustomer'),
  },
  {
    path: '/admin/report/booking-order',
    name: 'pages-report-booking-order',
    props: true,
    component: () => import('../views/pages/report/reportBookingOrder'),
  },
  {
    path: '/admin/report/credit',
    name: 'pages-report-credit',
    props: true,
    component: () => import('../views/pages/report/reportCredit'),
  },
  {
    path: '/admin/report/credit-money',
    name: 'pages-report-credit-money',
    props: true,
    component: () => import('../views/pages/report/reportCreditMoney'),
  },
  {
    path: '/admin/report/manage-sell',
    name: 'pages-report-manage-sell',
    props: true,
    component: () => import('../views/pages/report/reportManageSell'),
  },
  {
    path: '/admin/report/order-scan-billing',
    name: 'pages-order-scan-billing',
    props: true,
    component: () => import('../views/pages/report/reportOrderScanBill'),
  },
  {
    path: '/admin/report/billing-print',
    name: 'pages-billing-print',
    meta: {
      layout: 'blank',
    },
    component: () => import('../views/pages/report/billing-print'),
  },
  {
    path: '/admin/report/add-package',
    name: 'pages-report-add-package',
    props: true,
    component: () => import('../views/pages/package/reportAddPackage'),
  },

  {
    path: '/admin/add-package',
    name: 'pages-add-package',
    props: true,
    component: () => import('../views/pages/package/addPackage.vue'),
  },

  {
    path: '/admin/package',
    name: 'pages-package',
    props: true,
    component: () => import('../views/pages/package/package.vue'),
  },

  {
    path: '/admin/report/credit-package',
    name: 'pages-credit-package',
    props: true,
    component: () => import('../views/pages/credit-package/report.vue'),
  },

  {
    path: '/admin/add-credit-package',
    name: 'pages-add-credit-package',
    props: true,
    component: () => import('../views/pages/credit-package/addPackage.vue'),
  },

  {
    path: '/admin/credit-package',
    name: 'pages-manage-credit-package',
    props: true,
    component: () => import('../views/pages/credit-package/managePackage.vue'),
  },
  {
    path: '/admin/stock_employee/create',
    name: 'pages-stock-employee-create',
    props: true,
    component: () => import('../views/pages/stock-employee/create.vue'),
  },
  {
    path: '/admin/stock-employee/edit/:id',
    name: 'pages-stock-employee-edit',
    props: true,
    component: () => import('../views/pages/stock-employee/create.vue'),
  },
  {
    path: '/admin/stock-employee',
    name: 'pages-stock-employee',
    props: true,
    component: () => import('../views/pages/stock-employee/list.vue'),
  },
  {
    path: '/admin/report-product-amount',
    name: 'pages-report-product-amount',
    props: true,
    component: () => import('../views/pages/report/reportProductAmount.vue'),
  },
  {
    path: '/admin/point',
    name: 'pages-point',
    props: true,
    component: () => import('../views/pages/point/point.vue'),
  },
  {
    path: '/admin/product-point',
    name: 'pages-product-point',
    props: true,
    component: () => import('../views/pages/point/productPoint.vue'),
  },
  {
    path: '/admin/member-product-point',
    name: 'pages-member-product-point',
    props: true,
    component: () => import('../views/pages/point/memberProductPoint'),
  },
  {
    path: '/admin/credit-customer',
    name: 'pages-credit-customer',
    props: true,
    component: () => import('../views/pages/credit-customer/list.vue'),
  },

  {
    path: '/admin/credit-customer/report/add-cash/:id',
    name: 'pages-report-add-cash',
    props: true,
    component: () => import('../views/pages/credit-customer/list-add-cash.vue'),
  },

  {
    path: '/admin/credit-customer/report/add-credit/:id',
    name: 'pages-report-add-credit',
    component: () => import('../views/pages/credit-customer/list-add-credit.vue'),
  },

  {
    path: '/admin/main-data',
    name: 'pages-main-data',
    component: () => import('../views/pages/main-data/Setting.vue'),
  },
  {
    path: '/admin/profile',
    name: 'pages-profile',
    component: () => import('../views/pages/profile/index.vue'),
  },
  {
    path: '/admin/payment-setting',
    name: 'pages-payment-setting',
    component: () => import('../views/pages/main-data/Payment.vue'),
  },
  {
    path: '/admin/employee-type',
    name: 'pages-employee-type',
    component: () => import('../views/pages/employee/employee-type/EmployeeType.vue'),
  },
  {
    path: '/admin/employee/',
    name: 'pages-employee',
    component: () => import('../views/pages/employee/employess/Employee.vue'),
  },
  {
    path: '/admin/customer-type',
    name: 'pages-customers-type',
    component: () => import('../views/pages/member/customer-type/CustomerType.vue'),
  },
  {
    path: '/admin/customer',
    name: 'pages-customers',
    component: () => import('../views/pages/member/customer/Customer.vue'),
  },
  {
    path: '/admin/customer/customer_movement/:id',
    name: 'pages-customer-movement',
    component: () => import('../views/pages/member/customer/CustomerMovement.vue'),
  },
  {
    path: '/admin/role',
    name: 'pages-role',
    component: () => import('../views/pages/role/backend'),
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  const publicPages = ['/login', '/register', '/forget-password', '/tracking/']

  // const authRequired = !publicPages.filter(str => str.includes(to.path))
  const authRequired = !publicPages.includes(to.path)
  // eslint-disable-next-line no-undef
  const loggedIn = $cookies.get('token') ? $cookies.get('token') : null
  if (authRequired && !loggedIn && to.name !== 'pages-tracking') {
    next('/login')
  } else {
    const { permissions } = store.state
    let check = false

    if (!authRequired) {
      Object.entries(permissions)
        .forEach(([key, val]) => {
          if (to.name == permissions_pages[key]) {
            check = true
          }
        })
    }

    // if ((!check && to.name != 'pages-login')){
    //   no permission
    // console.log(5555)
    // }else{
    next()

    // }
  }
})
router.beforeResolve(async (to, from, next) => {
  try {
    const publicPages = ['/login', '/register', '/forget-password', '/tracking/']

    // const authRequired = !publicPages.filter(str => str.includes(to.path))
    const authRequired = !publicPages.includes(to.path)
    if (authRequired && to.name !== 'pages-tracking') {
      await store.dispatch('permissionCheck', to).then(res => {
        if (res.err) {
          next()
        } else if (res.permissions && res.permissions.length > 0 && res.permissions.user_home) {
          next({ name: 'user_home' })
        } else if (res.permissions && res.permissions.length == 0) {
          next({ name: 'pages-login' })
        } else {
          next({ name: from.name })
        }
      })
    } else {
      next()
    }
  } catch (e) {
    console.error(e, 444)
  }
})

export default router
