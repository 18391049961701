/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-param-reassign */
import axios from 'axios'

const baseURL = `${process.env.VUE_APP_BASEURL}`
const instance = axios.create({
  baseURL,
})

instance.interceptors.request.use(
  async config => {
    // const { $cookies } = window.App.config.globalProperties

    try {
      const accessToken = $cookies.get('token')
      config.headers.Authorization = `Bearer ${accessToken}`
    } catch (error) {
      setTimeout(() => {
      }, 300)
    }

    return config
  },
  error => {
    console.log('request error')

    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
  response =>
    response,
  error => {
    console.log('response error : ', error)
    if (error.response.status === 401) {
      Swal.fire({
        title: 'Session expired',
        text: 'Please sign in again!',
        type: 'error',
      })
        .then(result => {
          if (result.value) {
            // location.reload()
          }
        })
    }

    return Promise.reject(error)
  },
)

export default instance
