 import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import instance_user from "@/services/user";
export default new Vuex.Store({
  state: {
    isLoading : false,
    validate: false,
    data: '',
    bankList:[],
    selectBankking: {},
    member: {},
    order:{},
    step : 1,
    checkUpdateClicked : false,
    orderDetil: {
      // order_inv : true
    },
    transportTypePrice : 0,
    orderData: [],
    draft: {
      id: 'draft',
      amount: 1,
    },
    notpaid: {
      id: 'notpaid',
      amount: 0,
    },
    paid: {
      id: 'paid',
      amount: 0,
    },
    makeSend: {
      id: 'makeSend',
      amount: 0,
    },
    send: {
      id: 'send',
      amount: 1,
    },
    expire: {
      id: 'expire',
      amount: 0,
    },
    cancel: {
      id: 'cancel',
      amount: 0,
    },
    numberBill: 'RI-000002',
    nameCustomer: 'น้องพิมเพลิน',
    nameSupplier: 'บจก. C จำกัด',
    taxId: '01909019909471 (สำนักงานใหญ่)',
    addressSupplier: '9/99 หมู่ 9 ถนนเลขที่ 9',
    issueDate: '01/05/2022',
    taxInvoice: 'RX54555901',
    taxInvDate: '01/05/2022',
    PreparedBy: '',
    PurchaseOrder: 'PO-0000000003',
    projectName: '',
    Subtotal: 140000,
    SpecialDiscount: 0,
    AfterDiscount: 140000,
    valueAddTax: 9800,
    total: 149800,
    timePay: '30 กรกฎาคม 2565',
    finalPay: '6 สิงหาคม 2565',
    phoneNumberCustomer: '',
    addressCustomer: '',
    codeFollow: 'สามารถติดตามรหัสได้ที่นี่',
    note: '',
    activeButton: true,
    princeThia: 'หนึ่งแสนสี่หมื่นเก้าพันแปดร้อยบาทถ้วน',
    permissions : [],
    role_name : [],
    points : 0,
    credit_customers : 0,
    credit_products : 0,
    hasPermission : false,
    user: [
      // {
      //   id: 'wyw1',
      //   name: 'น้องพิมเพลิน',
      //   img: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png',
      //   link: 'facebook',
      //   numberBill: 101,
      //   timeBill: '28/7/2022 15:00',
      //   total: 100,
      //   status: 'ส่งแล้ว',
      // },
      // {
      //   id: 'wyw2',
      //   name: 'น้องมารวย',
      //   img: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png',
      //   link: 'line',
      //   numberBill: 102,
      //   timeBill: '18:00',
      //   total: 500,
      //   status: 'ร่าง',
      // },
    ],

    mockData: [
      // {
      //   id: 'mockData1',
      //   img: 'https://whatyouwantshop.com/storage/images/pattern_img/2021-02-09/20210209155553_60224e19c6142.jpg',
      //   nameProduct: 'เคสโทรศัพท์เคสด้าน',
      //   attachFile: ['(เคสใสขอบดำ)SAMSUNG NOTE10PLUS'],
      //   price: 390.00,
      //   amount: 1,
      //   description: '',
      //   review: '',
      //   product: ['เคสโทรศัพท์'],
      // },
      // {
      //   id: 'mockData2',
      //   img: 'https://whatyouwantshop.com/storage/images/pattern_img/2021-02-05/20210205103911_601cbddf077cc.jpg',
      //   nameProduct: 'เคสโทรศัพท์เคสด้าน',
      //   attachFile: ['(เคสใสขอบดำ)SAMSUNG NOTE10PLUS'],
      //   price: 390.00,
      //   amount: 1,
      //   description: '',
      //   review: '',
      //   product: ['เคสโทรศัพท์'],
      // },
    ],
    mockDataAddProduct: [
      {
        id: 'mockData1',
        img: 'https://cdn.pixabay.com/photo/2022/02/20/09/36/animal-7024080_1280.png',
        nameProduct: 'ขนมเบื้อง',
        price: 100,
        amount: 1,
      },
      {
        id: 'mockData2',
        img: 'https://cdn.pixabay.com/photo/2022/02/20/09/36/animal-7024080_1280.png',
        nameProduct: 'ขนมโก๋',
        price: 50,
        amount: 1,
      },
      {
        id: 'mockData3',
        img: 'https://cdn.pixabay.com/photo/2022/02/20/09/36/animal-7024080_1280.png',
        nameProduct: 'ขนมขาไก่',
        price: 50,
        amount: 1,
      },
      {
        id: 'mockData4',
        img: 'https://cdn.pixabay.com/photo/2022/02/20/09/36/animal-7024080_1280.png',
        nameProduct: 'ขนมปูไทย',
        price: 50,
        amount: 1,
      },
      {
        id: 'mockData5',
        img: 'https://cdn.pixabay.com/photo/2022/02/20/09/36/animal-7024080_1280.png',
        nameProduct: 'ขนมชั้น',
        price: 50,
        amount: 1,
      },
      {
        id: 'mockData6',
        img: 'https://cdn.pixabay.com/photo/2022/02/20/09/36/animal-7024080_1280.png',
        nameProduct: 'ขนมทองหยิบ',
        price: 50,
        amount: 1,
      },
      {
        id: 'mockData7',
        img: 'https://cdn.pixabay.com/photo/2022/02/20/09/36/animal-7024080_1280.png',
        nameProduct: 'ขนมทองหยอด',
        price: 50,
        amount: 1,
      },
      {
        id: 'mockData8',
        img: 'https://cdn.pixabay.com/photo/2022/02/20/09/36/animal-7024080_1280.png',
        nameProduct: 'ขนมไทย',
        price: 50,
        amount: 1,
      },
    ],
    mockDataStatistics: {
      allProduct: 4,
      bill: 2,
      salesToDays: 3000,
    },
    users: null,
    token: null,
    buttonStart: true,
    buttonGotoNotpay: false,
    buttonGotoPay: false,
    buttonGotoMakeSend: false,
    buttonGotoSend: false,
  },
  mutations: {
    setUser(state, users) {
      state.user = users
    },
    setToken(state, token) {
      state.token = token
    },
    setmockData(state, mockData) {
      state.mockData = mockData
    },
    setPermissions(state,payload){
      state.permissions = payload;
    },
    setRolename(state,payload){
      state.role_name = payload;
    },
    setOrderDetil(state,payload){
      state.orderDetil = payload;
    },
    setOrderData(state,payload){
      state.orderData = payload
    },
  },
  actions: {
    async permissionCheck(state,payload){
      var err = false;
      var permssions = [];
      await instance_user.checkPermission(payload).then(res => {
        if (res.data && res.data.success){
          this.state.hasPermission = res.data.status
          err = res.data.status
          permssions = res.data.permissions
        }else{
          this.state.hasPermission = false
          permssions = res.data.permissions

        }
        return this.state.hasPermission;
      }).catch(err => {
        this.state.hasPermission = false
        return false

      })
      return {
        permissions : permssions,
        err : err
      };
    }
  },
  modules: {},
})
