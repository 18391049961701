<template>
  <div>
    <loading :active.sync="$store.state.isLoading" :can-cancel="true" ></loading>
    <component :is="resolveLayout">
      <!-- <div v-if="token"> -->
      <router-view></router-view>
      <!-- </div> -->
      <!-- <div v-else> -->
      <!-- <Login></Login> -->
      <!-- </div> -->
    </component>
  </div>
</template>
<script>
import { computed } from '@vue/composition-api'
import { useRouter } from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'
import Login from '@/views/pages/Login.vue'
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.min.css';


export default {
  components: {
    Loading,
    LayoutBlank,
    LayoutContent,
    Login
  },
  setup() {
    const { route } = useRouter()
    const token = $cookies.get('token')
    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    })

    return {
      resolveLayout,
    }
  },
}
</script>
