require('./overrides.scss')

export default {
  theme: {
    themes: {
      light: {
        primary: '#8dbc8a',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFA07A',
        error: '#FF4C51',
        btnsuccess: '#3B9AE1',
        star: '#E4D192',
      },
      dark: {
        primary: '#8dbc8a',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFA07A',
        error: '#FF4C51',
      },
    },
  },
}
