<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="300"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <!-- <v-img
          :src="require('@/assets/images/logos/logo.jpg')"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img> -->
        <v-slide-x-transition>
          <h2 class="app-title text--primary">
            whatyouwantshop
          </h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5"
    >
      <!-- <nav-menu-link
        title="Dashboard"
        :to="{ name: 'dashboard' }"
        :icon="icons.mdiHomeOutline"
      ></nav-menu-link> -->
      <!-- <nav-menu-link title="สถิติ" :to="{ name: 'dashboard' }" :icon="icons.mdiGoogleAnalytics"></nav-menu-link> -->

      <!--      <nav-menu-link title="หน้าแรก" :to="{ name: 'pages-orders' }" :icon="icons.mdiCartArrowDown"></nav-menu-link>-->

      <nav-menu-link
        v-if="($store.state.permissions['user_home'])"
        :title="this.$store.state.permissions['user_home'] ? this.$store.state.permissions['user_home']['name_th'] : 'Home'"
        :to="{ name: 'user_home' }"
        :icon="icons.mdiViewDashboardOutline"
      >
      </nav-menu-link>

      <nav-menu-group
        v-if="($store.state.permissions['user_report'])"
        title="แพ็กเกจ/เติมเงิน"
        :icon="icons.mdiChartBar"
      >
        <nav-menu-link
          title="แพ็กเกจเครดิตสินค้า (ชิ้น)"
          :to="{ name: 'user-package' }"
        >
        </nav-menu-link>
        <nav-menu-link
          title="ติดตามการสั่งซื้อแพ็กเกจสินค้า"
          :to="{ name: 'user-follow-pay' }"
        >
        </nav-menu-link>
        <nav-menu-link
          title="ติดตามการสั่งซื้อแพ็กเกตเคิมเงินเข้าระบบ"
          :to="{ name: 'user-follow-package-credit' }"
        >
        </nav-menu-link>
      </nav-menu-group>
      <nav-menu-link
        v-if="($store.state.permissions['user_manage_sell_mem'])"
        :title="this.$store.state.permissions['user_manage_sell_mem'] ? this.$store.state.permissions['user_manage_sell_mem']['name_th'] : 'ออกบิลขาย'"
        :to="{ name: 'pages-orders' }"
        :icon="icons.mdiCartArrowDown"
      >
      </nav-menu-link>
      <nav-menu-link
        v-if="($store.state.permissions['user_claim'])"
        :title="this.$store.state.permissions['user_claim'] ? this.$store.state.permissions['user_claim']['name_th'] : 'เคลมสินค้า'"
        :to="{ name: 'pages-claim' }"
        :icon="icons.mdiNoteRemove"
      >
      </nav-menu-link>

      <nav-menu-link
        v-if="($store.state.permissions['user_stock_product'])"
        :title="this.$store.state.permissions['user_stock_product'] ? this.$store.state.permissions['user_stock_product']['name_th'] : 'ตรวจสอบปริมาณสินค้า'"
        :to="{ name: 'user-stock-product' }"
        :icon="icons.mdiQrcodeScan"
      >
      </nav-menu-link>
      <!--      <nav-menu-link-->
      <!--        v-if="($store.state.permissions['user_payment'])"-->
      <!--        :title="this.$store.state.permissions['user_payment'] ? this.$store.state.permissions['user_payment']['name_th'] : 'ช่องทางการชำระเงิน'"-->
      <!--        :to="{ name: 'pages-payment-setting' }" :icon="icons.mdiHome">-->
      <!--      </nav-menu-link>-->
      <nav-menu-group
        v-if="($store.state.permissions['user_report'])"
        title="รายงาน"
        :icon="icons.mdiChartBar"
      >
        <nav-menu-link
          title="แจ้งเลขแทรคไปรษณีย์"
          :to="{ name: 'user-report-tax' }"
        >
        </nav-menu-link>
        <nav-menu-link
          title="รายงานการสั่งซื้อ (ย้อนหลัง)"
          :to="{ name: 'user-report-booking-order' }"
        >
        </nav-menu-link>
        <nav-menu-link
          title="รายงานการเติมแพ็กเกจ"
          :to="{ name: 'user-report-package' }"
        >
        </nav-menu-link>
        <nav-menu-link
          title="รายงานเครดิตสินค้า"
          :to="{ name: 'user-report-credit' }"
        >
        </nav-menu-link>
        <nav-menu-link
          title="รายงานยอดเงินในระบบ"
          :to="{ name: 'user-credit-money' }"
        >
        </nav-menu-link>
        <nav-menu-link
          title="รายงานแลกสินค้า"
          :to="{ name: 'user-product-point' }"
        >
        </nav-menu-link>
      </nav-menu-group>

      <nav-menu-link
        v-if="(this.$store.state.permissions['all']) || ($store.state.permissions['admin_menu_home'])"
        :title="this.$store.state.permissions['all'] ? 'Home' :this.$store.state.permissions['admin_menu_home']['name_th']"
        :to="{ name: 'home' }"
        :icon="icons.mdiViewDashboardOutline"
      >
      </nav-menu-link>

      <nav-menu-group
        v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_account_all']) || ($store.state.permissions['purchase_order']) || ($store.state.permissions['purchase_order_data_entry'])"
        title="ระบบบัญชี"
        :icon="icons.mdiCalculator"
      >
        <nav-menu-link
          :title="($store.state.permissions['all']) || ($store.state.permissions['admin_account_all']) ? 'ใบสั่งซื้อสินค้า' : $store.state.permissions['purchase_order']['name_th']"
          :to="{ name: 'pages-purchase-order' }"
        ></nav-menu-link>
        <nav-menu-link
          :title="($store.state.permissions['all']) || ($store.state.permissions['admin_account_all']) || ($store.state.permissions['purchase_order_data_entry']) ? 'ใบรับสินค้า' : $store.state.permissions['purchase_order_data_entry']['name_th']"
          :to="{ name: 'pages-pickup' }"
        ></nav-menu-link>
        <nav-menu-link
          :title="($store.state.permissions['all']) || ($store.state.permissions['admin_account_all']) || ($store.state.permissions['purchase_order_data_entry']) ? 'ใบค่าใช้จ่าย' : $store.state.permissions['purchase_order_data_entry']['name_th']"
          :to="{ name: 'pages-cost' }"
        ></nav-menu-link>
      </nav-menu-group>

      <nav-menu-link
        v-if="($store.state.permissions['all']) ||($store.state.permissions['admin_scan_review'])"
        :title="($store.state.permissions['all']) ||($store.state.permissions['admin_scan_review']) ? 'ระบบสแกน รีวิวสินค้า' : $store.state.permissions['admin_scan_review']['name_th']"
        :to="{ name: 'pages-scan' }"
        :icon="icons.mdiQrcodeScan"
      >
      </nav-menu-link>

      <nav-menu-link
        v-if="($store.state.permissions['all']) ||($store.state.permissions['admin_payment'])"
        :title="($store.state.permissions['all']) ||($store.state.permissions['admin_payment']) ? 'แจ้งการชำระ' : $store.state.permissions['admin_payment']['name_th']"
        :to="{ name: 'pages-payment' }"
        :icon="icons.mdiContactlessPaymentCircle"
      >
      </nav-menu-link>

      <nav-menu-link
        v-if="($store.state.permissions['all']) ||($store.state.permissions['admin_menu_news'])"
        :title="($store.state.permissions['all']) ||($store.state.permissions['admin_menu_news']) ? 'ข่าวสาร' : $store.state.permissions['admin_menu_news']['name_th']"
        :to="{ name: 'pages-news' }"
        :icon="icons.mdiBellAlert"
      >
      </nav-menu-link>

      <nav-menu-link
        v-if="($store.state.permissions['all']) ||($store.state.permissions['admin_menu_message_api'])"
        :title="($store.state.permissions['all']) ||($store.state.permissions['admin_menu_message_api']) ? 'Line Message Api' : $store.state.permissions['admin_menu_message_api']['name_th']"
        :to="{ name: 'pages-message-api' }"
        :icon="icons.mdiSend"
      >
      </nav-menu-link>
      <!--      <nav-menu-link title="แชท" :to="{ name: 'pages-orders' }" :icon="icons.mdiCartArrowDown"></nav-menu-link>-->
      <!--      <nav-menu-link title="เมนูสินค้าผลิตเสีย" :to="{ name: 'pages-orders' }" :icon="icons.mdiCartArrowDown"></nav-menu-link>-->

      <!--      <nav-menu-link title="บิล" :to="{ name: 'pages-orders' }" :icon="icons.mdiCartArrowDown"></nav-menu-link>-->
      <!--      <nav-menu-link title="ลูกค้า" :to="{ name: 'pages-customers' }" :icon="icons.mdiAccountMultiple"></nav-menu-link>-->
      <!--      <nav-menu-link title="สินค้า" :to="{ name: 'pages-manage-product' }" :icon="icons.mdiShopping"></nav-menu-link>-->
      <!--      <nav-menu-link title="การส่งสินค้า" :to="{ name: 'pages-transit' }" :icon="icons.mdiTruck"></nav-menu-link>-->
      <!--      <nav-menu-link-->
      <!--        title="หน้าร้านออนไลน์"-->
      <!--        :to="{ name: 'pages-storefront' }"-->
      <!--        :icon="icons.mdiCartVariant"-->
      <!--      ></nav-menu-link>-->
      <!--      <nav-menu-link-->
      <!--        title="การตั้งค่าบัญชี"-->
      <!--        :to="{ name: 'pages-account-settings' }"-->
      <!--        :icon="icons.mdiAccountCogOutline"-->
      <!--      ></nav-menu-link>-->

      <!--      &lt;!&ndash; Buy Groups &ndash;&gt;-->
      <!--&lt;!&ndash;      <nav-menu-group title="ซื้อ" :icon="icons.mdiContentPaste">&ndash;&gt;-->
      <!--&lt;!&ndash;        <nav-menu-link title="ใบสั่งซื้อ" :to="{ name: 'pages-purchase-order' }" ></nav-menu-link>&ndash;&gt;-->
      <!--&lt;!&ndash;        <nav-menu-link title="รับสินค้า" :to="{ name: 'pages-pickup' }" ></nav-menu-link>&ndash;&gt;-->
      <!--&lt;!&ndash;        <nav-menu-link title="ซัพพลายเออร์" :to="{ name: 'pages-supplier' }" ></nav-menu-link>&ndash;&gt;-->
      <!--&lt;!&ndash;      </nav-menu-group>&ndash;&gt;-->

      <!--      &lt;!&ndash; Sales Groups &ndash;&gt;-->
      <!--      <nav-menu-group title="ขาย" :icon="icons.mdiCash">-->
      <!--        <nav-menu-link title="ชำระเงิน" :to="{ name: 'pages-pay' }" ></nav-menu-link>-->
      <!--        <nav-menu-link title="หัก ณ ที่จ่าย" :to="{ name: 'pages-tax' }" ></nav-menu-link>-->
      <!--      </nav-menu-group>-->

      <!--      &lt;!&ndash; Employess Groups &ndash;&gt;-->
      <!--      <nav-menu-group title="พนักงาน" :icon="icons.mdiAccount">-->
      <!--        <nav-menu-link title="เงินเดือน" :to="{ name: 'pages-salary' }" ></nav-menu-link>-->
      <!--      </nav-menu-group>-->

      <!--      &lt;!&ndash; Report Groups &ndash;&gt;-->
      <!--      <nav-menu-group title="รีพอร์ต" :icon="icons.mdiClockTimeFour">-->
      <!--        <nav-menu-link title="งบการเงิน" :to="{ name: 'pages-financial-statement' }" ></nav-menu-link>-->
      <!--        <nav-menu-link title="บัญชี" :to="{ name: 'pages-audit' }" ></nav-menu-link>-->
      <!--        <nav-menu-link title="การขาย" :to="{ name: 'pages-sales' }" ></nav-menu-link>-->
      <!--        <nav-menu-link title="สินค้า" :to="{ name: 'pages-product' }" ></nav-menu-link>-->
      <!--      </nav-menu-group>-->

      <!-- Page Groups -->
      <!--      <nav-menu-group title="Pages" :icon="icons.mdiFileOutline">-->
      <!--        <nav-menu-link title="Login" :to="{ name: 'pages-login' }" ></nav-menu-link>-->
      <!--        <nav-menu-link title="Register" :to="{ name: 'pages-register' }" ></nav-menu-link>-->
      <!--        <nav-menu-link title="Error" :to="{ name: 'error-404' }" ></nav-menu-link>-->
      <!--      </nav-menu-group>-->

      <nav-menu-link
        v-if="($store.state.permissions['all']) ||($store.state.permissions['admin_menu_message_api'])"
        :title="($store.state.permissions['all']) ||($store.state.permissions['admin_product_worn_out']) ? 'ตัดยอดสินค้า/ผลิตเสีย' : $store.state.permissions['admin_product_worn_out']['name_th']"
        :to="{ name: 'pages-product-worn_out' }"
        :icon="icons.mdiNoteRemove"
      ></nav-menu-link>

      <nav-menu-group
        v-if="($store.state.permissions['all']) ||($store.state.permissions['admin_stock_all'])||
          ($store.state.permissions['admin_phone_brand'])||($store.state.permissions['admin_phone_model']) ||
          ($store.state.permissions['admin_product_stock'])||($store.state.permissions['admin_product_online']) ||
          ($store.state.permissions['admin_product_type'])||($store.state.permissions['admin_pattern']) ||
          ($store.state.permissions['pattern_set_prices'] ||($store.state.permissions['pattern_set_prices']))"
        :title="($store.state.permissions['all']) ||($store.state.permissions['price_rates']) ? 'ระบบสินค้าคงคลัง' : 'ระบบสินค้าคงคลัง'"
        :icon="icons.mdiWarehouse"
      >
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_stock_all'])|| ($store.state.permissions['admin_phone_brand']) "
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_stock_all']) ? 'จัดการยี่ห้อโทรศัพท์/ชนิดสินค้า' : $store.state.permissions['admin_phone_brand']['name_th']"
          :to="{ name: 'pages-phone-brand' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_stock_all'])|| ($store.state.permissions['admin_phone_model']) "
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_stock_all']) ? 'จัดการรุ่นโทรศัพท์/รายละเอียดสินค้า' : $store.state.permissions['admin_phone_model']['name_th']"
          :to="{ name: 'pages-phone-model' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_stock_all'])|| ($store.state.permissions['admin_product_stock']) "
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_stock_all']) ? 'จัดการสินค้า (ภายใน)' : $store.state.permissions['admin_product_stock']['name_th']"
          :to="{ name: 'pages-product-offline' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_stock_all'])|| ($store.state.permissions['admin_product_online']) "
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_stock_all']) ? 'จัดการสินค้า (ออนไลน์)' : $store.state.permissions['admin_product_online']['name_th']"
          :to="{ name: 'pages-product-online' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_stock_all'])|| ($store.state.permissions['admin_product_type']) "
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_stock_all']) ? 'จัดการประเภทสินค้า' : $store.state.permissions['admin_product_type']['name_th']"
          :to="{ name: 'pages-product-type' }"
        ></nav-menu-link>
        <!--        <nav-menu-link-->
        <!--          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_stock_all'])|| ($store.state.permissions['admin_pattern']) "-->
        <!--          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_stock_all']) ? 'จัดการลายสินค้า' : $store.state.permissions['admin_pattern']['name_th']"-->
        <!--          :to="{ name: 'pages-pattern' }" ></nav-menu-link>-->
        <!--        <nav-menu-link-->
        <!--          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_stock_all'])|| ($store.state.permissions['pattern_set_prices']) "-->
        <!--          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_stock_all']) ? 'ราคาพิเศษสำหรับลายร้าน' : $store.state.permissions['pattern_set_prices']['name_th']"-->
        <!--          :to="{ name: 'pages-pattern-set-price' }" ></nav-menu-link>-->
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_stock_all'])|| ($store.state.permissions['price_rates']) "
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_stock_all']) ? 'อัตราเรทราคาสินค้า(ชิ้น)' : $store.state.permissions['price_rates']['name_th']"
          :to="{ name: 'pages-price-rate' }"
        ></nav-menu-link>
      </nav-menu-group>
      <nav-menu-group
        v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_reconcile_all']) || ($store.state.permissions['admin_reconcile_amount']) || ($store.state.permissions['admin_reconcile_report'])"
        :title="($store.state.permissions['all']) ||($store.state.permissions['admin_reconcile_all']) ? 'ปรับยอดสินค้า' : 'ปรับยอดสินค้า'"
        :icon="icons.mdiGamepadCircle"
      >
        <nav-menu-link
          title="ปรับยอดสินค้า"
          :to="{ name: 'pages-reconcile' }"
        ></nav-menu-link>
        <nav-menu-link
          title="รายงานปรับยอด"
          :to="{ name: 'pages-report-reconcile' }"
        ></nav-menu-link>
      </nav-menu-group>

      <nav-menu-group
        v-if="($store.state.permissions['all']) ||($store.state.permissions['admin_report_all'])||
          ($store.state.permissions['admin_report_tax_number'])||($store.state.permissions['admin_report_booking_order']) ||
          ($store.state.permissions['admin_report_package'])||($store.state.permissions['admin_report_credit']) ||
          ($store.state.permissions['admin_report_credit_money'])||($store.state.permissions['admin_report_credit_money']) ||
          ($store.state.permissions['admin_report_package_credit'] ||($store.state.permissions['admin_order_scan_billing']))"
        title="ระบบรายงาน"
        :icon="icons.mdiChartBar"
      >
        <nav-menu-link
          :title="'ภาพรวม'"
          :to="{ name: 'pages-report-overall' }"
        ></nav-menu-link>
        <nav-menu-link
          :title="'ยอดขาย'"
          :to="{ name: 'pages-report-sell' }"
        ></nav-menu-link>
        <!--        <nav-menu-link-->
        <!--          :title="'ยอดซื้อ'"-->
        <!--          :to="{ name: 'pages-report-buy' }"-->
        <!--        ></nav-menu-link>-->
        <nav-menu-link
          :title="'สินค้า'"
          :to="{ name: 'pages-report-product' }"
        ></nav-menu-link>
        <nav-menu-link
          :title="'ลูกค้า'"
          :to="{ name: 'pages-report-customer' }"
        ></nav-menu-link>

        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_report_all'])|| ($store.state.permissions['admin_report_tax_number']) "
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_report_all']) ? 'แจ้งเลขแทรคไปรษณีย์' : $store.state.permissions['admin_report_tax_number']['name_th']"
          :to="{ name: 'pages-report-tax-number' }"
        ></nav-menu-link>

        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_report_all'])|| ($store.state.permissions['admin_report_booking_order']) "
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_report_all']) ? 'รายการสั่งซื้อ' : $store.state.permissions['admin_report_booking_order']['name_th']"
          :to="{ name: 'pages-report-booking-order' }"
        ></nav-menu-link>

        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_report_all']) || ($store.state.permissions['admin_report_credit']) "
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_report_all']) ? 'รายงานเครดิต(สินค้า)' : $store.state.permissions['admin_report_credit']['name_th']"
          :to="{ name: 'pages-report-credit' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_report_all'])|| ($store.state.permissions['admin_report_credit_money']) "
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_report_all']) ? 'รายงานเครดิต(เงิน)' : $store.state.permissions['admin_report_credit_money']['name_th']"
          :to="{ name: 'pages-report-credit-money' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_report_all'])|| ($store.state.permissions['admin_report_manage_sell']) "
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_report_all']) ? 'สรุปรายงาน' : $store.state.permissions['admin_report_manage_sell']['name_th']"
          :to="{ name: 'pages-report-manage-sell' }"
        >
        </nav-menu-link>
      </nav-menu-group>
      <nav-menu-group
        v-if="($store.state.permissions['all']) ||($store.state.permissions['admin_sell_all'])||
          ($store.state.permissions['admin_bank'])||($store.state.permissions['admin_transport_type']) ||
          ($store.state.permissions['admin_manage_sell_mem'])||($store.state.permissions['admin_claim']) ||
          ($store.state.permissions['transport_expense_rates'])|| ($store.state.permissions['admin_config'])"
        title="ระบบการขาย"
        :icon="icons.mdiCartArrowDown"
      >
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_sell_all']) || ($store.state.permissions['admin_manage_sell_mem']) "
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_sell_all']) ? 'ออกบิลขาย' : $store.state.permissions['admin_manage_sell_mem']['name_th']"
          :to="{ name: 'pages-orders' }"
        ></nav-menu-link>
        <!--        <nav-menu-link-->
        <!--          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_sell_all']) || ($store.state.permissions['admin_manage_sell_mem']) "-->
        <!--          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_sell_all']) ? 'pages-order-create' : $store.state.permissions['admin_manage_sell_mem']['name_th']"-->
        <!--          :to="{ name: 'pages-order-create' }" ></nav-menu-link>-->
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_sell_all'])|| ($store.state.permissions['admin_claim']) "
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_sell_all']) ? 'เคลมสินค้า' : $store.state.permissions['admin_claim']['name_th']"
          :to="{ name: 'pages-claim' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_sell_all'])|| ($store.state.permissions['admin_bank']) "
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_sell_all']) ? 'บัญชีธนาคาร' : $store.state.permissions['admin_bank']['name_th']"
          :to="{ name: 'pages-bank' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_sell_all'])|| ($store.state.permissions['admin_transport_type']) "
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_sell_all']) ? 'ตั้งค่าการจัดส่ง' : $store.state.permissions['admin_transport_type']['name_th']"
          :to="{ name: 'pages-transport-type' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_sell_all'])|| ($store.state.permissions['transport_expense_rate']) "
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_sell_all']) ? 'อัตราคาขนส่ง' : $store.state.permissions['transport_expense_rate']['name_th']"
          :to="{ name: 'pages-transport-expense-rate' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_sell_all'])|| ($store.state.permissions['admin_config']) "
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_sell_all']) ? 'ตั้งค่าใช้จ่ายใน Order' : $store.state.permissions['admin_config']['name_th']"
          :to="{ name: 'pages-config' }"
        ></nav-menu-link>
      </nav-menu-group>

      <nav-menu-group
        v-if="($store.state.permissions['all']) ||($store.state.permissions['admin_package_all'])||
          ($store.state.permissions['admin_package_report'])||($store.state.permissions['admin_package_add']) ||
          ($store.state.permissions['admin_package_manage'])"
        title="ระบบแพ็กเกจ"
        :icon="icons.mdiPackage"
      >
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_package_all'])|| ($store.state.permissions['admin_package_report']) "
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_package_all']) ? 'รายงานการเติมแพ็กเกจ' : $store.state.permissions['admin_package_report']['name_th']"
          :to="{ name: 'pages-report-add-package' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_package_all']) || ($store.state.permissions['admin_package_add']) "
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_package_all']) ? 'เติมแพ็กเกจ' : $store.state.permissions['admin_package_add']['name_th']"
          :to="{ name: 'pages-add-package' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_package_all']) || ($store.state.permissions['admin_package_manage']) "
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_package_all']) ? 'จัดการแพ็กเกจ' : $store.state.permissions['admin_package_manage']['name_th']"
          :to="{ name: 'pages-package' }"
        ></nav-menu-link>
      </nav-menu-group>

      <nav-menu-group
        v-if="($store.state.permissions['all']) ||($store.state.permissions['admin_package_credit_all'])||
          ($store.state.permissions['admin_package_credit_report'])||($store.state.permissions['admin_package_credit_add']) ||
          ($store.state.permissions['admin_package_credit_manage'])"
        title="ระบบแพ็กเกจเติมเงิน"
        :icon="icons.mdiCreditCardCheck"
      >
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_package_credit_all']|| ($store.state.permissions['admin_package_credit_report']))"
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_package_credit_all']) ? 'รายงานการเติมแพ็กเกจ' : $store.state.permissions['admin_package_credit_report']['name_th']"
          :to="{ name: 'pages-credit-package' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_package_credit_all']|| ($store.state.permissions['admin_package_credit_add']))"
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_package_credit_all']) ? 'เติมแพ็กเกจ' : $store.state.permissions['admin_package_credit_add']['name_th']"
          :to="{ name: 'pages-add-credit-package' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_package_credit_all']|| ($store.state.permissions['admin_package_credit_manage']))"
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_package_credit_all']) ? 'จัดการเติมแพ็กเกจ' : $store.state.permissions['admin_package_credit_manage']['name_th']"
          :to="{ name: 'pages-manage-credit-package' }"
        ></nav-menu-link>
      </nav-menu-group>

      <nav-menu-group
        v-if="($store.state.permissions['all']) ||($store.state.permissions['stock_employee_all'])||
          ($store.state.permissions['stock_employee_create'] ||($store.state.permissions['stock_employee_report']))"
        title="ระบบเบิกสำหรับพนักงาน"
        :icon="icons.mdiWidgets"
      >
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_report_all'])|| ($store.state.permissions['admin_order_scan_billing']) "
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_report_all']) ? 'ระบบสแกนบิล' : $store.state.permissions['admin_order_scan_billing']['name_th']"
          :to="{ name: 'pages-order-scan-billing' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['stock_employee_all']) || ($store.state.permissions['stock_employee_create'])"
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_point_all']) ? 'สร้างใบเบิก' : $store.state.permissions['stock_employee_create']['name_th']"
          :to="{ name: 'pages-stock-employee-create' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['stock_employee_all']) || ($store.state.permissions['stock_employee_report'])"
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_point_all']) ? 'รายการเบิก' : $store.state.permissions['stock_employee_report']['name_th']"
          :to="{ name: 'pages-stock-employee' }"
        ></nav-menu-link>
        <nav-menu-link
          title="ประวัติกการตัดสต๊อค"
          :to="{ name: 'pages-report-product-amount' }"
        ></nav-menu-link>
      </nav-menu-group>

      <nav-menu-group
        v-if="($store.state.permissions['all']) ||($store.state.permissions['admin_point_all'])||
          ($store.state.permissions['admin_point_manage'])||($store.state.permissions['admin_point_manage_product'])"
        title="ระบบแจกคะแนน"
        :icon="icons.mdiCounter"
      >
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_point_all']) || ($store.state.permissions['admin_point_manage'])"
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_point_all']) ? 'จัดการคะแนน' : $store.state.permissions['admin_point_manage']['name_th']"
          :to="{ name: 'pages-point' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_point_all']) || ($store.state.permissions['admin_point_manage_product'])"
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_point_all']) ? 'จัดการสินค้ารางวัล' : $store.state.permissions['admin_point_manage_product']['name_th']"
          :to="{ name: 'pages-product-point' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_point_all']) || ($store.state.permissions['admin_point_manage_product'])"
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_point_all']) ? 'จัดการสินค้าแลกคะแนน' : $store.state.permissions['admin_point_manage_product']['name_th']"
          :to="{ name: 'pages-member-product-point' }"
        ></nav-menu-link>
      </nav-menu-group>

      <nav-menu-link
        v-if="($store.state.permissions['all']) ||($store.state.permissions['admin_credit_customers'])"
        title="จัดการเครดิตลูกค้า"
        :to="{ name: 'pages-credit-customer' }"
        :icon="icons.mdiAccountCogOutline"
      >
      </nav-menu-link>

      <!--      &lt;!&ndash; Page Groups &ndash;&gt;-->
      <nav-menu-group
        v-if="($store.state.permissions['all']) ||($store.state.permissions['admin_data_shop_all'])||
          ($store.state.permissions['admin_main_data'])||($store.state.permissions['admin_payments'])"
        title="จัดการข้อมูลร้าน"
        :icon="icons.mdiHome"
      >
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_data_shop_all']) || ($store.state.permissions['admin_main_data'])"
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_data_shop_all']) ? 'ตั้งค่าปิด/เปิดระบบหลังบ้าน' : $store.state.permissions['admin_main_data']['name_th']"
          :to="{ name: 'pages-main-data' }"
        ></nav-menu-link>
        <!--        <nav-menu-link-->
        <!--          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_data_shop_all']) || ($store.state.permissions['admin_payments'])"-->
        <!--          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_data_shop_all']) ? 'ช่องทางการชำระเงิน' : $store.state.permissions['admin_payments']['name_th']"-->
        <!--          :to="{ name: 'pages-payment-setting' }" ></nav-menu-link>-->
      </nav-menu-group>

      <nav-menu-group
        v-if="($store.state.permissions['all']) ||($store.state.permissions['admin_employee_all'])||
          ($store.state.permissions['admin_employee_type_manage'])||($store.state.permissions['admin_employee'])"
        title="ระบบพนักงาน"
        :icon="icons.mdiAccount"
      >
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_employee_all'])"
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_employee_all']) ? 'จัดการกลุ่มพนักงาน' : $store.state.permissions['admin_employee_type_manage']['name_th']"
          :to="{ name: 'pages-employee-type' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_employee_all'])"
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_employee_all']) ? 'จัดการข้อมูลพนักงาน' : $store.state.permissions['admin_employee_manage']['name_th']"
          :to="{ name: 'pages-employee' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="($store.state.permissions['all']) ||($store.state.permissions['admin_role_all']) ||($store.state.permissions['admin_role'])"
          title="ระบบหลังร้าน"
          :to="{ name: 'pages-role' }"
        ></nav-menu-link>
      </nav-menu-group>

      <nav-menu-group
        v-if="($store.state.permissions['all']) ||($store.state.permissions['admin_member_all'])||
          ($store.state.permissions['admin_member_type_manage'])||($store.state.permissions['admin_member_manage'])"
        title="ระบบลูกค้า"
        :icon="icons.mdiAccountGroup"
      >
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_member_all'])|| ($store.state.permissions['admin_member_type_manage']) "
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_member_all']) ? 'จัดการกลุ่มลูกค้า' : $store.state.permissions['admin_member_type_manage']['name_th']"
          :to="{ name: 'pages-customers-type' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="($store.state.permissions['all']) || ($store.state.permissions['admin_member_all'])|| ($store.state.permissions['admin_member_manage']) "
          :title="($store.state.permissions['all']) ||($store.state.permissions['admin_member_all']) ? 'จัดการข้อมูลลูกค้า' : $store.state.permissions['admin_member_manage']['name_th']"
          :to="{ name: 'pages-customers' }"
        ></nav-menu-link>
      </nav-menu-group>

      <!--      <nav-menu-group-->
      <!--        v-if="($store.state.permissions['all']) ||($store.state.permissions['admin_role'])"-->
      <!--           title="บทบาท" :icon="icons.mdiCog">-->
      <!--        <nav-menu-link-->
      <!--          v-if="($store.state.permissions['all']) ||($store.state.permissions['admin_role_all']) ||($store.state.permissions['admin_role'])"-->
      <!--          title="ระบบหลังร้าน" :to="{ name: 'pages-role' }" ></nav-menu-link>-->
      <!--      </nav-menu-group>-->

      <nav-menu-section-title title="ติดต่อฝ่ายสนับสนุน"></nav-menu-section-title>
      <nav-menu-link
        title="WHATYOUWANT59@hotmail.com"
        href="mailto:WHATYOUWANT59@hotmail.com"
        :icon="icons.mdiEmail"
      ></nav-menu-link>
      <nav-menu-link
        title="Line"
        href="https://lin.ee/3BNnHap"
        :icon="icons.mdiForum"
      ></nav-menu-link>
      <nav-menu-link
        title="0836456265"
        href="tel:0836456265"
        :icon="icons.mdiPackage"
      ></nav-menu-link>
      <!-- <nav-menu-link title="Icons" :to="{ name: 'icons' }" :icon="icons.mdiEyeOutline"></nav-menu-link> -->
      <nav-menu-link
        title="ชุมชนผู้ใช้งาน"
        href="tel:0836456265"
        :icon="icons.mdiAccountGroup"
      ></nav-menu-link>
      <nav-menu-link
        title="ร้องเรียน แนะนำบริการ"
        href="tel:0956965451"
        :icon="icons.mdiTruck"
      ></nav-menu-link>
      <!-- <nav-menu-link title="Cards" href="https://www.facebook.com/" :icon="icons.mdiCreditCardOutline"></nav-menu-link> -->
      <!-- <nav-menu-link title="Tables" :to="{ name: 'simple-table' }" :icon="icons.mdiTable"></nav-menu-link> -->
      <v-btn
        color="primary"
        class="mt-1 ms-5 buttonStyle"
        href="https://www.facebook.com/whatyouwant59"
      >
        สนทนาผ่าน Messenger
      </v-btn>
    </v-list>
    <!-- <a
      href="https://themeselection.com/products/materio-vuetify-vuejs-admin-template"

      rel="nofollow"
    >
      <v-img
        :src="require(`@/assets/images/pro/upgrade-banner-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
        alt="upgrade-banner"
        transition="scale-transition"
        class="upgrade-banner mx-auto"
        max-width="230"
      ></v-img>
    </a> -->
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiCartVariant,
  mdiTruck,
  mdiShopping,
  mdiGoogleAnalytics,
  mdiAccountMultiple,
  mdiCartArrowDown,
  mdiEmail,
  mdiAccountGroup,
  mdiForum,
  mdiCash,
  mdiContentPaste,
  mdiAccount,
  mdiClockTimeFour,
  mdiBellAlert,
  mdiCalculator,
  mdiSend,
  mdiNoteRemove,
  mdiGamepadCircle,
  mdiChartBar,
  mdiPackage,
  mdiCreditCardCheck,
  mdiWidgets,
  mdiCounter,
  mdiHome,
  mdiCog,
  mdiWarehouse,
  mdiViewDashboardOutline,
  mdiQrcodeScan,
  mdiContactlessPaymentCircle,
} from '@mdi/js'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'
import instance_user from '../../../services/user'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
    return {
      icons: {
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiCartVariant,
        mdiTruck,
        mdiShopping,
        mdiGoogleAnalytics,
        mdiAccountMultiple,
        mdiCartArrowDown,
        mdiEmail,
        mdiAccountGroup,
        mdiForum,
        mdiCash,
        mdiContentPaste,
        mdiAccount,
        mdiClockTimeFour,
        mdiBellAlert,
        mdiCalculator,
        mdiSend,
        mdiNoteRemove,
        mdiGamepadCircle,
        mdiChartBar,
        mdiPackage,
        mdiCreditCardCheck,
        mdiWidgets,
        mdiCounter,
        mdiHome,
        mdiCog,
        mdiWarehouse,
        mdiViewDashboardOutline,
        mdiQrcodeScan,
        mdiContactlessPaymentCircle,
      },
    }
  },
  mounted() {
    instance_user.getAuth().then(res => {
      if (res.status === 200) {
        this.$store.commit('setPermissions', res.data.permissions)
        this.$store.commit('setRolename', res.data.role_name)
        this.$store.commit('setUser', res.data.user)
        this.$store.state.points = res.data.point
        this.$store.state.credit_customers = res.data.credit_customer
        this.$store.state.credit_products = res.data.credit_product
        this.$store.state.credit_product_amt_expire = res.data.credit_product_amt_expire
      }

      if (res.data.error || res.error === 'Unauthorized') {
        // eslint-disable-next-line no-undef
        $cookies.remove('token')
        this.$router.push({ name: 'pages-login' })
      }
    }).catch(err => {

    })
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.vertical-nav-menu-items {
  .buttonStyle {
    width: 90%;
  }
}
</style>
