<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <!--        <v-card-title class="d-flex align-center justify-center py-7">-->
        <!--          <router-link-->
        <!--            to="/"-->
        <!--            class="d-flex align-center"-->
        <!--          >-->
        <!--            <v-img-->
        <!--              :src="require('@/assets/images/logos/logo.svg')"-->
        <!--              max-height="30px"-->
        <!--              max-width="30px"-->
        <!--              alt="logo"-->
        <!--              contain-->
        <!--              class="me-3 "-->
        <!--            ></v-img>-->

        <!--            <h2 class="text-2xl font-weight-semibold">-->
        <!--              WhatYouWant-->
        <!--            </h2>-->
        <!--          </router-link>-->
        <!--        </v-card-title>-->

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2 text-center">
            WhatYouWant
          </p>
          <p class="mb-2 text-center">
            สำหรับตัวแทนจำหน่าย/ผู้ดูแลระบบ
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="email"
              outlined
              label="อีเมล"
              placeholder="อีเมล"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="รหัสผ่าน"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <!--              <v-checkbox-->
              <!--                label="Remember Me"-->
              <!--                hide-details-->
              <!--                class="me-3 mt-1"-->
              <!--              >-->
              <!--              </v-checkbox>-->

              <!-- forgot link -->


              <!--              <a-->
              <!--                href="https://www.whatyouwantshop.com/"-->
              <!--                class="mt-1">-->
              <!--                หน้าเว็บหลัก-->
              <!--              </a>-->
            </div>
            <div class="mt-2 text-right">

              <router-link :to="{name : 'pages-forget-password'}">
                ลืมรหัสผ่าน
              </router-link>
            </div>

            <v-btn
              block
              color="primary"
              class="mt-6"
              @click="login()"
            >
              เข้าสู่ระบบ
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <!--          <span class="me-2">-->
          <!--            New on our platform?-->
          <!--          </span>-->
          <router-link :to="{name:'pages-register'}">
            สมัครเป็นตัวแทนออนไลน์
          </router-link>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <!--          <span class="me-2">-->
          <!--            New on our platform?-->
          <!--          </span>-->
          <a href="https://whatyouwant59.com/">
            หน้าเว็บหลัก
          </a>
        </v-card-text>

        <!--        &lt;!&ndash; divider &ndash;&gt;-->
        <!--        <v-card-text class="d-flex align-center mt-2">-->
        <!--          <v-divider></v-divider>-->
        <!--          <span class="mx-5">or</span>-->
        <!--          <v-divider></v-divider>-->
        <!--        </v-card-text>-->

        <!--        &lt;!&ndash; social links &ndash;&gt;-->
        <!--        <v-card-actions class="d-flex justify-center">-->
        <!--          <v-btn-->
        <!--            v-for="link in socialLink"-->
        <!--            :key="link.icon"-->
        <!--            icon-->
        <!--            class="ms-1"-->
        <!--          >-->
        <!--            <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">-->
        <!--              {{ link.icon }}-->
        <!--            </v-icon>-->
        <!--          </v-btn>-->
        <!--        </v-card-actions>-->
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline
} from '@mdi/js'
import {ref} from '@vue/composition-api'
import {mapMutations} from 'vuex'
import axios from 'axios'
import instance_user from "@/services/user";
import Swal from "sweetalert2";

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const email = ref('')
    const password = ref('')
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isPasswordVisible,
      email,
      password,
      socialLink,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  data() {
    return {
      permission_name: {
        all: 'home',
        admin_menu_home: 'home',

        admin_account_all: 'pages-purchase-order',
        purchase_order: 'pages-purchase-order',
        purchase_order_data_entry: 'pages-pickup',

        admin_scan_review: 'pages-scan',

        admin_payment: 'pages-payment',

        admin_menu_news: 'pages-news',

        admin_menu_message_api: 'pages-message-api',

        admin_product_worn_out: 'pages-product-worn_out',

        admin_stock_all: 'pages-phone-brand',
        admin_phone_brand: 'pages-phone-brand',
        admin_phone_model: 'pages-phone-model',
        admin_product_stock: 'pages-product-offline',
        admin_product_online: 'pages-product-online',
        admin_product_type: 'pages-product-type',
        admin_pattern: 'pages-pattern',
        pattern_set_prices: 'pages-pattern-set-price',
        price_rates: 'pages-price-rate',

        admin_reconcile_amount: 'pages-reconcile',
        admin_reconcile_report: 'pages-report-reconcile',

        admin_report_all: 'pages-report-tax-number',
        admin_report_tax_number: 'pages-report-tax-number',
        admin_report_booking_order: 'pages-report-booking-order',
        admin_report_package: 'pages-claim',
        admin_report_credit: 'pages-report-credit',
        admin_report_credit_money: 'pages-report-credit-money',
        admin_report_package_credit: 'pages-claim',
        admin_order_scan_billing: 'pages-order-scan-billing',

        admin_sell_all: 'pages-orders',
        admin_bank: 'pages-bank',
        admin_transport_type: 'pages-transport-type',
        admin_manage_sell_mem: 'pages-orders',
        admin_claim: 'pages-claim',
        transport_expense_rates: 'pages-transport-expense-rate',
        admin_config: 'pages-config',

        admin_package_all: 'pages-report-add-package',
        admin_package_report: 'pages-report-add-package',
        admin_package_add: 'pages-add-package',
        admin_package_manage: 'pages-package',

        admin_package_credit_all: 'pages-credit-package',
        admin_package_credit_report: 'pages-credit-package',
        admin_package_credit_add: 'pages-add-credit-package',
        admin_package_credit_manage: 'pages-manage-credit-package',

        stock_employee_all: 'pages-stock-employee',
        stock_employee_create: 'pages-stock-employee-create',
        stock_employee_report: 'pages-report-product-amount',

        admin_point_all: 'pages-point',
        admin_point_manage: 'pages-point',
        // admin_point_manage_product : 'pages-product-point',
        admin_point_manage_product: 'pages-member-product-point',

        admin_credit_customers: 'pages-credit-customer',

        admin_data_shop_all: 'pages-main-data',
        admin_main_data: 'pages-main-data',
        admin_payments: 'pages-payment-setting',

        admin_employee_all: 'pages-employee',
        admin_employee_type_manage: 'pages-employee-type',
        admin_employee: 'pages-employee',

        admin_member_all: 'pages-customers',
        admin_member_type_manage: 'pages-customers-type',
        admin_member_manage: 'pages-customers',

        admin_role_all: 'pages-role',
        admin_role: 'pages-role',


        user_home: 'user_home',
        user_manage_sell_mem: 'user-stock-product',
        user_profile: 'pages-profile',
        user_report: '',
        user_stock_product: 'user-stock-product',
        user_payment: 'pages-payment-setting',
        user_claim: 'pages-claim',

      }
    }
  },
  methods: {
    ...mapMutations(['setUser', 'setToken']),
    login() {
      const data = {
        email: this.email,
        password: this.password,
      }
      // eslint-disable-next-line no-useless-concat
      const baseURL = `${process.env.VUE_APP_BASEURL}` + '/login'
      axios
        .post(baseURL, data)
        .then(res => {
          if (res.status === 200) {
            // eslint-disable-next-line no-undef
            if (res.data.success) {
              $cookies.set('token', res.data.token)

              instance_user.getAuth().then(res => {
                if (res.status === 200) {
                  this.$store.commit('setPermissions', res.data.permissions);
                  this.$store.commit('setRolename', res.data.role_name);
                  this.$store.commit('setUser', res.data.user);

                  var to = null;
                  Object.entries(res.data.permissions).forEach(([key, val]) => {
                    if (this.permission_name[key] && !to) {
                      to = {name: this.permission_name[key]};
                      return true

                    }

                  });
                  this.$router.push(to)
                }


              }).catch(err => {
                Swal.fire({
                  icon: 'error',
                  title: err.message ?? err.data.message,
                  showConfirmButton: false,
                  timer: 3000
                })
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: "เกิดข้อผิดพลาด",
                text: res.message ?? res.data.message,
                showConfirmButton: false,
                timer: 3000
              })
            }
          }
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: error.message ?? error.data.message,
            showConfirmButton: false,
            timer: 1500
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
