/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
import axios from 'axios'
import api from '../axios.service'

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
}
const baseURL = `${process.env.VUE_APP_BASEURL}`
export default {
  login(userAuth) {
    const params = new URLSearchParams()
    params.append('email', userAuth.username)
    params.append('password', userAuth.password)


    return axios.post(`${baseURL}/login`, userAuth, config)
  },
  register(data){
    let getFormData = data

    let params = new FormData();
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }

    return axios.post(`${baseURL}/register`, params, config)
  },
  forgotPassword(data){

    const params = new URLSearchParams()
    params.append('email', data.email)

    return axios.post(`${baseURL}/forgot_password`, params, config)
  },
  logout(userAuth) {
    return axios.post(`${baseURL}/logout`)
  },
  refreshToken() {
    const params = new URLSearchParams()
    params.append('token', $cookies.get('token'))

    return axios.post(`${baseURL}/refresh`, params, config)
  },
  getUser() {
    return api.get('/user')
  },
  getAuth() {
    return api.get('get_auth')
  },
  getProfile() {
    return api.get('get_auth')
  },
  removrToken(res) {
    if (res.data.error || res.error === 'Unauthorized') {
      // eslint-disable-next-line no-undef
      $cookies.remove('token')
      window.location.reload()
    }
  },
  checkPermission(data){
    let getFormData = data

    let params = new FormData();
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }

    return api.post('checkPermission',params,config);
  }
}
