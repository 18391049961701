import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import AxiosPlugin from 'vue-axios-cors'
import VueMoment from 'vue-moment'
import moment from 'moment'
import vSelect from 'vue-select'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import CKEditor from "@ckeditor/ckeditor5-vue2";
import VueCompositionAPI from '@vue/composition-api'
import './assets/style/style.css';
import VueQRCodeComponent from 'vue-qrcode-component'

Vue.config.productionTip = false
Vue.use(VueMoment)
Vue.use(moment)
Vue.use(CKEditor);
Vue.use(VueCompositionAPI)

Vue.component('qr-code', VueQRCodeComponent)
Vue.component('anyothername-select', vSelect)
new Vue({
  router,
  store,
  vuetify,
  VueCookies,
  AxiosPlugin,
  render: h => h(App),
}).$mount('#app')
